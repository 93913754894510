import React from "react";
import LanguagePicker from "../LanguagePicker";
import logo from "../../assets/logo.png";
import "./styles.css";

const Header = () => {
  return (
    <header className="header">
      <img src={logo} alt="logo" className="logo" />

      <LanguagePicker />
    </header>
  );
};

export default Header;
