import React from "react";
import { useTranslation } from "react-i18next";
import useOnClickOutside from "use-onclickoutside";
import roFlag from "../../assets/ro-flag.svg";
import enFlag from "../../assets/en-flag.svg";
import frFlag from "../../assets/fr-flag.svg";
import arrowDown from "../../assets/arrow-down.svg";
import "./styles.css";

const LANGUAGES = [
  {
    name: "en",
    flag: enFlag
  },
  {
    name: "fr",
    flag: frFlag
  },
  {
    name: "ro",
    flag: roFlag
  }
];

const LanguagePicker = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const ref = React.useRef(null);
  const { i18n } = useTranslation();

  useOnClickOutside(ref, () => {
    setIsOpen(false);
  });

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  const activeLanguage =
    LANGUAGES.find(language => language.name === i18n.language) || LANGUAGES[0];

  return (
    <div className="language-picker" ref={ref}>
      <button
        aria-haspopup="true"
        aria-expanded={isOpen}
        className="language-picker__button"
        onClick={() => setIsOpen(!isOpen)}
      >
        <img
          src={activeLanguage.flag}
          alt="en"
          className="language-picker__flag"
        />
        <span aria-hidden className="language-picker__arrow">
          <img src={arrowDown} alt="arrow down" />
        </span>
      </button>
      {isOpen && (
        <div className="language-picker__list">
          {LANGUAGES.map(language => (
            <button
              key={language.name}
              className="language-picker__lang"
              onClick={() => {
                changeLanguage(language.name);
                setIsOpen(false);
              }}
            >
              <img
                src={language.flag}
                alt={language.name}
                className="language-picker__flag"
              />
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default LanguagePicker;
