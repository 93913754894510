import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import * as Sentry from "@sentry/browser";
import { ErrorBoundary } from "react-error-boundary";
import "./index.css";
import App from "./App";
import ErrorFallback from "./components/ErrorFallback";
import * as serviceWorker from "./serviceWorker";
import "./i18n";

Sentry.init({
  dsn:
    "https://23dd038aeeab415dab3e9f914c85276f@o102273.ingest.sentry.io/5251940",
});

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const errorHandler = (error, componentStack) => {
  Sentry.withScope((scope) => {
    scope.setExtras(componentStack);
    Sentry.captureException(error);
  });
};

ReactDOM.render(
  <Elements
    stripe={stripePromise}
    options={{
      fonts: [
        {
          cssSrc: "https://fonts.googleapis.com/css2?family=Lato&display=swap",
        },
      ],
    }}
  >
    <Suspense fallback={null}>
      <Router>
        <ErrorBoundary FallbackComponent={ErrorFallback} onError={errorHandler}>
          <App />
        </ErrorBoundary>
      </Router>
    </Suspense>
  </Elements>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
