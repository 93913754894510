import React, { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import Header from "./components/Header";

const Intro = lazy(() => import("./components/Intro"));
const Selection = lazy(() => import("./components/Selection"));
const Donate = lazy(() => import("./components/Donate"));
const ThankYou = lazy(() => import("./components/ThankYou"));
const Terms = lazy(() => import("./components/Terms"));

const App = () => {
  return (
    <div className="container">
      <Header />

      <Routes>
        <Route path="/" element={<Intro />} />
        <Route path="selection" element={<Selection />} />
        <Route path="donate" element={<Donate />} />
        <Route path="thank-you" element={<ThankYou />} />
        <Route path="terms" element={<Terms />} />
      </Routes>
    </div>
  );
};

export default App;
