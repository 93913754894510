import React from "react";
import "./styles.css";

const ErrorFallback = ({ error, componentStack, resetErrorBoundary }) => {
  return (
    <div className="error-fallback-container">
      <div role="alert" className="error-fallback">
        <p>Something went wrong:</p>
        <pre>{error.message}</pre>
        <pre>{componentStack}</pre>
        <button onClick={resetErrorBoundary}>Try again</button>
      </div>
    </div>
  );
};

export default ErrorFallback;
